import React from 'react';

import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MetricData from './MetricData.jsx';
import MetricStatusIcon from './MetricStatusIcon.jsx';

function MetricGroup({
  // Props
  name,
  metrics,
  metricsList,
  weeks,
  setTableReload,
}) {
  //---------------------------------------------------------------------------
  // States
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);
  const ref = React.useRef(null);

  //---------------------------------------------------------------------------
  // Callback Functions
  //---------------------------------------------------------------------------
  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);

  React.useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        const y = ref.current?.getBoundingClientRect().top;
        if (y) {
          window.scrollTo({ top: y + window.scrollY - 70, behavior: 'smooth' });
        }
      }, 300);
    }
  }, [expanded]);

  return (
    <Card square ref={ref} sx={{ my: 0.5, ...(expanded && { my: 1, boxShadow: 6 }) }}>
      <Grid
        container
        onClick={handleExpandClick}
        alignItems="center"
        sx={{ p: '0.5rem', minHeight: '3.5rem', ...(expanded && { fontWeight: 'bold' }) }}
      >
        <Grid item xs={3}>
          {name}
        </Grid>
        <Grid item xs={6}>
          {metrics.map((metric) => (
            <Stack key={metric.id} direction="row" spacing={1}>
              <Typography>{metric.name}</Typography>
              <MetricStatusIcon metric={metric} />
            </Stack>
          ))}
        </Grid>
        <Grid item xs={3}>
          {metrics.map((metric) => (
            <Typography key={metric.id}>{metric.user}</Typography>
          ))}
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
        <MetricData
          groupName={name}
          metrics={metrics}
          metricsList={metricsList}
          weeks={weeks}
          setTableReload={setTableReload}
        />
      </Collapse>
    </Card>
  );
}

export default MetricGroup;
