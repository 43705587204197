import React from 'react';
import { DateTime } from 'luxon';
import { transparentize } from 'polished';

import CalculateIcon from '@mui/icons-material/Calculate';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  blueberryTwilightPalette,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
} from '@mui/x-charts';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';

import DataPointForm from '../../components/DataPointForm.jsx';
import MetricForm from '../../components/MetricForm.jsx';
import ValueWithUnits, { useValueWithUnits } from '../../components/ValueWithUnits.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';

const columns = 8;

const getWeekDataMax = (weekData, filterFunction) => {
  const rawMax = Math.max(
    ...weekData.map((week) => {
      const filteredPoints = week.dataPoints.filter(filterFunction);
      if (!filteredPoints.length) {
        return 0;
      }
      return Math.max(...filteredPoints.map((el) => el.value || 0));
    })
  );
  const ceiling = rawMax / 15;

  return Math.ceil(rawMax + ceiling);
};

function MetricData({
  // Props
  groupName,
  metrics,
  metricsList,
  weeks,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);
  const { roles } = useAuth0Roles();
  const canEditMetrics = React.useMemo(
    () => editMode && roles.includes('Scorecard Admin'),
    [editMode, roles]
  );

  const weekData = React.useMemo(() => {
    const now = DateTime.local().set({ weekday: 5 });
    return Array.from({ length: weeks }, (_, i) => weeks - i).map((offset) => {
      const date = now.minus({ weeks: offset }).toISODate();

      const dataPoints = metrics.map((metric) => {
        if (metric.dataType === 'calculated') {
          const numerator = metricsList
            .find((el) => el.id === metric.calculationNumerator)
            ?.DataPoints.find((el) => el.date === date)?.value;
          const denominator = metricsList
            .find((el) => el.id === metric.calculationDenominator)
            ?.DataPoints.find((el) => el.date === date)?.value;
          if (numerator === undefined) {
            return { metric };
          }
          const value = metric.calculationDenominator ? numerator / denominator : numerator;
          return {
            value: metric.calculationScalar ? metric.calculationScalar * value : value,
            metric,
          };
        }
        const dataPoint = metric.DataPoints.find((el) => el.date === date);
        return {
          id: dataPoint?.id,
          value: dataPoint?.value,
          notes: dataPoint?.notes,
          metric,
        };
      });

      return {
        date,
        dataPoints,
      };
    });
  }, [metrics, metricsList, weeks]);
  const averages = React.useMemo(() => {
    const list = metrics.map(({ id, goal, goalType, goalUnits, fixedDigits }) => ({
      id,
      xSum: 0,
      ySum: 0,
      xxSum: 0,
      xySum: 0,
      count: 0,
      average: 0,
      goal,
      goalType,
      goalUnits,
      fixedDigits,
    }));
    return weekData.reduce((acc, el) => {
      el.dataPoints.forEach((point, index) => {
        if (![undefined, null].includes(point.value)) {
          const xValue = DateTime.fromISO(el.date).toMillis() / 6.048e8;
          const yValue = point.value;
          acc[index].xSum += xValue;
          acc[index].ySum += yValue;
          acc[index].xxSum += xValue * xValue;
          acc[index].xySum += xValue * yValue;
          acc[index].count += 1;
        }
      });
      return acc.map((metric) => ({
        ...metric,
        average: metric.ySum / metric.count,
        slope:
          (metric.count * metric.xySum - metric.xSum * metric.ySum) /
          (metric.count * metric.xxSum - metric.xSum * metric.xSum),
        intercept: metric.ySum / metric.count - (metric.slope * metric.xSum) / metric.count,
      }));
    }, list);
  }, [metrics, weekData]);

  const theme = useTheme();

  const rightAxis = React.useMemo(
    () => ({
      id: metrics.find((metric) => metric.axisId === 'rightAxisId')?.id,
      max: getWeekDataMax(weekData, (el) => el.metric.axisId === 'rightAxisId' && el.value),
    }),
    [metrics, weekData]
  );

  const leftAxis = React.useMemo(
    () => ({
      id: metrics.find((metric) => metric.axisId !== 'rightAxisId')?.id,
      max: getWeekDataMax(weekData, (el) => el.metric.axisId !== 'rightAxisId' && el.value),
    }),
    [metrics, weekData]
  );

  const xAxis = React.useMemo(() => {
    return [
      {
        scaleType: 'time',
        valueFormatter: (date) => {
          if (typeof date === 'number') {
            return DateTime.fromMillis(date).toISODate();
          }
          return DateTime.fromJSDate(date).toISODate();
        },
        data: weekData.map((week) => {
          return DateTime.fromISO(week.date).toMillis();
        }),
      },
    ];
  }, [weekData]);
  const formatValue = useValueWithUnits();
  const yAxis = React.useMemo(
    () =>
      metrics.map((metric, index) => {
        const goodColor = blueberryTwilightPalette(theme.mode)[index];
        const badColor = transparentize(0.5, goodColor);
        return {
          id: metric.id,
          min: 0,
          max: metric.axisId === 'rightAxisId' ? rightAxis.max : leftAxis.max,
          // eslint-disable-next-line react/no-unstable-nested-components
          valueFormatter: (value) =>
            formatValue({
              value,
              units: metric.goalUnits,
              fixedDigits: metric.fixedDigits,
            }),
          colorMap: {
            type: 'piecewise',
            thresholds: [metric.goal],
            colors:
              metric.goalType === 'greaterThan' ? [badColor, goodColor] : [goodColor, badColor],
          },
        };
      }),
    [formatValue, leftAxis.max, metrics, rightAxis.max, theme.mode]
  );

  const series = React.useMemo(() => {
    return metrics.map((metric) => {
      return {
        connectNulls: true,
        label: metric.name,
        type: 'line',
        yAxisKey: metric.id,
        // eslint-disable-next-line react/no-unstable-nested-components
        valueFormatter: (value) => (
          <ValueWithUnits value={value} units={metric.goalUnits} fixedDigits={metric.fixedDigits} />
        ),
        data: weekData.map((week) => {
          return week.dataPoints.find((el) => el.metric.id === metric.id)?.value;
        }),
      };
    });
  }, [metrics, weekData]);

  //
  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ResponsiveChartContainer
            xAxis={xAxis}
            series={series}
            yAxis={yAxis}
            skipAnimation
            height={650}
            margin={{ left: 90, right: 100 }}
          >
            <LinePlot />
            <MarkPlot />
            <ChartsXAxis />
            <ChartsYAxis axisId={leftAxis.id} />
            <ChartsYAxis axisId={rightAxis.id} position="right" />
            <ChartsTooltip />
            <ChartsLegend direction="row" />
          </ResponsiveChartContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={0}>
            <Grid container columnSpacing={1} columns={columns}>
              <Grid item xs={2}>
                <Typography fontSize="1.2rem">Date</Typography>
              </Grid>
              {metrics.map((metric) => (
                <Grid key={metric.id} item xs={2}>
                  <Typography fontSize="1.2rem">{`${metric.name} (${metric.user})`}</Typography>
                </Grid>
              ))}
            </Grid>
            <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
            <Grid container alignItems="center" columnSpacing={1} columns={columns}>
              <Grid item xs={2}>
                GOAL
              </Grid>
              {metrics.map((metric) => (
                <Grid key={metric.id} item xs={2}>
                  <Stack alignItems="center" direction="row">
                    <ValueWithUnits
                      value={metric.goal}
                      units={metric.goalUnits}
                      goalType={metric.goalType}
                      fixedDigits={metric.fixedDigits}
                    />
                    {canEditMetrics && (
                      <MetricForm
                        metric={metric}
                        metricsList={metricsList}
                        setTableReload={setTableReload}
                      />
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Grid container alignItems="center" columnSpacing={1} columns={columns}>
              <Grid item xs={2}>
                Average
              </Grid>
              {averages.map((metric) => (
                <Grid key={metric.id} item xs={2}>
                  <Stack alignItems="center" direction="row">
                    <Typography
                      sx={{
                        ...(((metric.goalType === 'lessThan' && metric.average > metric.goal) ||
                          (metric.goalType === 'greaterThan' && metric.average < metric.goal)) && {
                          color: theme.palette.warning.main,
                        }),
                      }}
                    >
                      <ValueWithUnits
                        value={metric.average}
                        units={metric.goalUnits}
                        fixedDigits={metric.fixedDigits}
                      />
                    </Typography>
                    <IconButton color="primary" disabled>
                      <CalculateIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Grid container alignItems="center" columnSpacing={1} columns={columns}>
              <Grid item xs={2}>
                Linear Weekly Growth
              </Grid>
              {averages.map((metric) => (
                <Grid key={metric.id} item xs={2}>
                  <Stack alignItems="center" direction="row">
                    <Typography
                      sx={{
                        ...(((metric.goalType === 'lessThan' && metric.slope > 0) ||
                          (metric.goalType === 'greaterThan' && metric.slope < 0)) && {
                          color: theme.palette.warning.main,
                        }),
                      }}
                    >
                      <ValueWithUnits
                        value={metric.slope}
                        units={metric.goalUnits}
                        fixedDigits={metric.fixedDigits}
                      />
                    </Typography>
                    <CalculateIcon color="disabled" sx={{ mx: 1 }} />
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
            {weekData.map((week) => (
              <Grid
                key={`${groupName}-${week.date}`}
                columnSpacing={1}
                columns={columns}
                container
                alignItems="center"
              >
                <Grid item xs={2}>
                  <Typography>{week.date}</Typography>
                </Grid>
                {week.dataPoints.map(({ id, metric, value, date, notes }) => (
                  <Grid key={`${metric.id}-${date}`} item xs={2}>
                    <Stack alignItems="center" direction="row">
                      {![undefined, null].includes(value) && (
                        <Typography
                          sx={{
                            ...(((metric.goalType === 'lessThan' && value > metric.goal) ||
                              (metric.goalType === 'greaterThan' && value < metric.goal)) && {
                              color: theme.palette.warning.main,
                            }),
                          }}
                        >
                          <ValueWithUnits
                            value={value}
                            units={metric.goalUnits}
                            fixedDigits={metric.fixedDigits}
                            tooltip={notes}
                          />
                        </Typography>
                      )}
                      {metric.dataType === 'manual' ? (
                        <DataPointForm
                          id={id}
                          date={week.date}
                          value={value}
                          notes={notes}
                          metric={metric}
                          setTableReload={setTableReload}
                        />
                      ) : (
                        <IconButton color="primary" disabled>
                          <CalculateIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MetricData;
