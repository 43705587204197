import React from 'react';
import { useLocation } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';

import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { useFilter, useInterval, useSort } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import EditButton from '../../components/EditButton.jsx';
import MetricForm from '../../components/MetricForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import MetricGroup from './MetricGroup.jsx';
import MetricsHeader from './MetricsHeader.jsx';

//-----------------------------------------------------------------------------
// Page configuration
//-----------------------------------------------------------------------------
const DATA_REFRESH_INTERVAL_MS = 15000;
const searchFields = {
  person: 'user',
  group: 'group',
  metric: 'name',
};
const sortOptions = {
  defaultSort: [
    {
      field: 'group',
      reverse: false,
    },
    {
      field: 'user',
      reverse: false,
    },
    {
      field: 'name',
      reverse: false,
    },
  ],
};

//-----------------------------------------------------------------------------
function ScorecardPage({
  // Props
  company,
  group,
}) {
  const location = useLocation();

  const [metricsList, setMetricsList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  const { roles } = useAuth0Roles();
  const canEdit = React.useMemo(() => roles.includes('Scorecard Admin'), [roles]);

  const [weeks, setWeeks] = React.useState(13);
  const handlePeriodChange = React.useCallback((event) => {
    if (event.target.value === 'month') {
      setWeeks(5);
    } else if (event.target.value === 'year') {
      setWeeks(52);
    } else {
      setWeeks(13);
    }
    setTableLoading(true);
  }, []);

  //---------------------------------------------------------------------------
  // Navbar button
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    setPageButtons(
      <span>
        <ListItem>
          <TextField
            label="Time Period"
            variant="outlined"
            fullWidth
            select
            defaultValue="quarter"
            onChange={handlePeriodChange}
          >
            <MenuItem value="quarter">Quarter</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </TextField>
        </ListItem>
        {canEdit && (
          <>
            <ListItem>
              <MetricForm
                setTableReload={setTableLoading}
                metricsList={metricsList}
                company={company}
              >
                Add Metric
              </MetricForm>
            </ListItem>
            <ListItem sx={{ mb: 1 }}>
              <EditButton>Edit Data</EditButton>
            </ListItem>
          </>
        )}
      </span>
    );
    return () => setPageButtons(null);
  }, [setPageButtons, canEdit, metricsList, company, handlePeriodChange, location]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getMetrics = React.useCallback(async () => {
    try {
      if (!error) {
        const date = DateTime.now().minus({ weeks }).toISODate();
        const { data } = await axios({
          method: 'GET',
          url: '/api/metrics',
          params: { company, group, date },
        });

        setMetricsList(data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [company, error, group, weeks]);
  useInterval(getMetrics, DATA_REFRESH_INTERVAL_MS, tableLoading && !error);

  //---------------------------------------------------------------------------
  // Search
  //---------------------------------------------------------------------------
  const { search, setSearch } = React.useContext(SearchContext);
  const filteredMetrics = useFilter(metricsList, search, searchFields);
  const [sortedMetrics, handleSortSelection, sort] = useSort(filteredMetrics, sortOptions);
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setMetricsList([]);
      setTableLoading(true);
      setError('');
    };
  }, [setSearch, location]);

  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  const groupedMetrics = React.useMemo(() => groupBy(sortedMetrics, 'group'), [sortedMetrics]);

  if (tableLoading && !metricsList.length) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <MetricsHeader sort={sort} setSort={handleSortSelection} />
      {Object.keys(groupedMetrics).map((key) => (
        <React.Fragment key={key}>
          <MetricGroup
            name={key}
            metrics={groupedMetrics[key]}
            metricsList={metricsList}
            weeks={weeks}
            setTableReload={setTableLoading}
          />
        </React.Fragment>
      ))}
    </>
  );
}

export default ScorecardPage;
